import React from "react"
import Page from '../components/Page'
import Layout from "../components/Layout"
import { graphql } from 'gatsby'
import StoryblokService from '../utils/storyblok-service'
import { PageContext } from "src/utils/context";
 
export default class Index extends React.Component {
  state = {
    story: {
       name: this.props.data.story ? this.props.data.story.name : '',
       content: this.props.data.story ? JSON.parse(this.props.data.story.content) : {}
    },
    cta_referrer: null
  }
 
  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search)
    let { data: { story } } = await StoryblokService.get(`cdn/stories/${this.props.data.story.full_slug}`)
    return story
  }
 
  async componentDidMount() {
    let story = await this.getInitialStory()
    if(story.content) this.setState({ story })
    setTimeout(() => StoryblokService.initEditor(this), 200)
  }

  setCtaReferrer = (value) => {
    this.setState({ cta_referrer: value })
  }

  render() {
    return (
      <PageContext.Provider
        value={{
          hasHero:
            this.state.story.content.body &&
            ["hero", "hero-custom"].includes(this.state.story.content.body[0]?.component)
              ? true
              : false,
          currentPage: this.state.story.uuid,
          breadcrumb: this.props.pageContext.breadcrumb,
          location: this.props.location,
          setCtaReferrer: this.setCtaReferrer,
          ctaReferrer: this.state.cta_referrer,
          parent_id: this?.state?.story?.parent_id,
        }}
      >
        <Layout location={this.props.location}>
          <Page blok={this.state.story.content} name={this.state.story.name} />
        </Layout>
      </PageContext.Provider>
    )
  }
}
 
export const query = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "en/" }) {
      name
      content
      full_slug
      uuid
    }
  }
`